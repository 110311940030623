import Link from '@bluheadless/ui/src/molecules/link/link'
import { ImageArtDirection } from '@bluheadless/ui/src/particles/art-direction'
import { cx } from '@emotion/css'
import { useMediaQuery } from '@mui/material'
import { array, node, number, object, string } from 'prop-types'
import { Root, Title, Subtitle } from './image-banner.styled'

const ImageBanner = ({
	title,
	titleProps,
	subtitle,
	subtitleProps,
	ImageComponent,
	imageSources,
	imageProps,
	imageLink,
	imageLinkTarget,
	imageAlt,
	cta,
	className,
	TitleComponent,
	SubtitleComponent,
	...props
}) => {
	const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
	const imageOriginalWidth = mdUp ? imageSources?.md?.originalWidth : imageSources?.xs?.originalWidth
	const imageOriginalHeight = mdUp ? imageSources?.md?.originalHeight : imageSources?.xs?.originalHeight

	return (
		<Root className={cx('ImageBanner-root', className, imageLink && 'can-hover')} {...props}>
			<Link href={imageLink} target={imageLinkTarget} onClick={cta?.[0]?.onClick} className="img">
				<ImageComponent
					src={mdUp ? imageSources?.md?.src : imageSources?.xs?.src}
					alt={imageAlt}
					originalWidth={imageOriginalWidth}
					originalHeight={imageOriginalHeight}
					sources={imageSources}
					{...imageProps}
				/>
			</Link>
			{title && (
				<Link href={imageLink} target={imageLinkTarget} onClick={cta?.[0]?.onClick}>
					<TitleComponent className="title" {...titleProps}>
						{title}
					</TitleComponent>
				</Link>
			)}
			{subtitle && (
				<SubtitleComponent className="subtitle" {...subtitleProps}>
					{subtitle}
				</SubtitleComponent>
			)}
		</Root>
	)
}

ImageBanner.defaultProps = {
	ImageComponent: ImageArtDirection,
	TitleComponent: Title,
	SubtitleComponent: Subtitle,
}
ImageBanner.propTypes = {
	title: node,
	titleProps: object,
	imageSrc: string,
	imageOriginalWidth: number,
	imageOriginalHeight: number,
	imageSources: object,
	imageProps: object,
	imageLink: string,
	imageLinkTarget: string,
	cta: array,
	className: string,
}

export default ImageBanner
