import Typography from '@bluheadless/ui/src/atoms/typography/typography'
import styled from '@emotion/styled'

export const Root = styled('div')`
	position: relative;
	a {
		text-decoration: none;
	}
	.img {
		span {
			border-radius: calc(var(--card-border-radius) * 2);
			${({ theme }) => theme.breakpoints.up('md')} {
				border-radius: calc(var(--card-border-radius) * 5);
			}
		}
	}
	&.can-hover {
		img {
			transition: transform 0.5s;
			animation-timing-function: ${({ theme }) => theme.transitions.easing.easeInOut};
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
`

export const Title = styled(Typography)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	padding: ${({ theme }) => theme.spacing(0.5)};
	font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	color: ${({ theme }) => theme.palette.common.white};
	text-transform: uppercase;
	text-align: center;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	}
`

export const Subtitle = styled(Typography)`
	text-align: center;
`
